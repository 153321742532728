<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm">
        <CLink
          class="d-block mb-3"
          color="info"
          @click="copyFromOpeningTimeToDeliveryTime"
        >
          <span v-if="copySucceeded.openingTime"><CIcon name="cil-check-alt" /> Copied!</span>
          <span v-else><CIcon name="cil-clone" class="mr-1" />Copy From Opening Time</span>
        </CLink>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-4">
        <CButton
          class="px-0"
          color="link"
          @click="deliveryTimesCopyTo"
        >
          <span v-if="copySucceeded.deliveryTimesCopyTo"><CIcon name="cil-check-alt" class="mr-1" />Applied!</span>
          <span v-else><CIcon name="cil-copy" class="mr-1" />Apply Monday to all days</span>
        </CButton>
      </div>
      <div class="col-sm-3 d-none d-sm-block px-sm-0"><h5 class="mb-0">Open from</h5></div>
      <div class="col-sm-3 d-none d-sm-block"><h5 class="mb-0">Open till</h5></div>
    </div>

    <restaurant-times @isValid="(value) => isValidDeliveryTime.mo = value" day='Monday' :time.sync="$v.form.deliver_monday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.tu = value" day='Tuesday' :time.sync="$v.form.deliver_tuesday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.we = value" day='Wednesday' :time.sync="$v.form.deliver_wednesday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.th = value" day='Thursday' :time.sync="$v.form.deliver_thursday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.fr = value" day='Friday' :time.sync="$v.form.deliver_friday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.sa = value" day='Saturday' :time.sync="$v.form.deliver_saturday.$model" />
    <restaurant-times @isValid="(value) => isValidDeliveryTime.su = value" day='Sunday' :time.sync="$v.form.deliver_sunday.$model" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"

export default {
  name: 'RestaurantWizardStep4',
  components: {
    RestaurantTimes: () => import('@/components/RestaurantTimes'),
  },
  data() {
    return {
      form: { },
      isValidDeliveryTime: {'mo': true, 'tu': true, 'we': true, 'th': true, 'fr': true, 'sa': true, 'su': true, },
      copySucceeded: {
        openingTime: null,
        deliveryTimesCopyTo: null,
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      deliver_monday: {},
      deliver_tuesday: {},
      deliver_wednesday: {},
      deliver_thursday: {},
      deliver_friday: {},
      deliver_saturday: {},
      deliver_sunday: {},
    }
  },

  async mounted() {
    this.form = this.getForm();

    this.$nextTick(function () {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return Object.values(this.isValidDeliveryTime).every(Boolean); },
  },

  methods: {
    getForm() {
      const storedForm = localStorage.getItem('pending-restaurant');

      if (storedForm) {
        var form = JSON.parse(storedForm);

        form.deliver_monday = form.deliver_monday || "";
        form.deliver_tuesday = form.deliver_tuesday || "";
        form.deliver_wednesday = form.deliver_wednesday || "";
        form.deliver_thursday = form.deliver_thursday || "";
        form.deliver_friday = form.deliver_friday || "";
        form.deliver_saturday = form.deliver_saturday || "";
        form.deliver_sunday = form.deliver_sunday || "";

        return form;
      } else {
        this.$emit("reset");
      }
    },

    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("previous", true);
    },


    deliveryTimesCopyTo() {
      this.form.deliver_tuesday =
      this.form.deliver_wednesday =
      this.form.deliver_thursday =
      this.form.deliver_friday =
      this.form.deliver_saturday =
      this.form.deliver_sunday = this.form.deliver_monday;

      this.copySucceeded.deliveryTimesCopyTo = true;
      setTimeout(() => this.copySucceeded.deliveryTimesCopyTo = false, 5000);
    },

    copyFromOpeningTimeToDeliveryTime() {
      this.form.deliver_monday = this.form.open_monday;
      this.form.deliver_tuesday = this.form.open_tuesday;
      this.form.deliver_wednesday = this.form.open_wednesday;
      this.form.deliver_thursday = this.form.open_thursday;
      this.form.deliver_friday = this.form.open_friday;
      this.form.deliver_saturday = this.form.open_saturday;
      this.form.deliver_sunday = this.form.open_sunday;

      this.copySucceeded.openingTime = true;
      setTimeout(() => this.copySucceeded.openingTime = false, 5000);
    },
  }
}
</script>
